<template>
  <div ref="NotFound" class="not-found text-justify flex">
    <div class="not-found-container">
      <div class="flex-sm">
        <h3 class="not-found-container-element text-center text-sm-right">
          {{ $t("notFound.es.title") }}
        </h3>
        <h3 class="not-found-container-element text-center text-sm-left">
          {{ $t("notFound.en.title") }}
        </h3>
      </div>
      <hr class="primary" />
      <div class="flex-sm">
        <a
          :href="$utils.getPageRoute('/')"
          :title="applicationName"
          class="not-found-container-element text-center text-sm-right general-anchor decorated"
        >
          <span>{{ $t("notFound.es.ctaTitle") }}</span>
        </a>
        <a
          :href="$utils.getPageRoute('/en/')"
          :title="applicationName"
          class="not-found-container-element text-center text-sm-left general-anchor decorated"
        >
          <span>{{ $t("notFound.en.ctaTitle") }}</span>
        </a>
      </div>
      <hr class="secondary" />
      <h1 class="text-center">404</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  computed: {
    /*
     * @computed - applicationName
     * Gets name of the application
     */
    applicationName() {
      return this.$store.state.Global.env.APPLICATION_NAME;
    }
  }
};
</script>

<style lang="scss" scoped>
.not-found {
  min-height: calc(100vh - 4.25rem);

  @include breakpoint($small) {
    min-height: calc(100vh - 5rem);
  }

  @include breakpoint($medium) {
    min-height: auto;
  }

  &-container {
    padding: 1.5rem 0.75rem;
    flex: 1 0;
    overflow: auto;

    @include breakpoint($small) {
      padding: 2rem 1.25rem;
    }

    &-element {
      display: block;
      margin: 1rem 0.5rem;

      @include breakpoint($xsmall) {
        margin: 1rem 2rem;
      }

      @include breakpoint($small) {
        flex: 1 0;
      }

      @include breakpoint($medium) {
        margin: 1rem 4rem;
      }

      @include breakpoint($large) {
        margin: 1rem 5rem;
      }
    }

    h1 {
      font-size: 6.5rem;
      margin: 0;

      @include breakpoint($medium) {
        font-size: 10rem;
        margin: 1rem 0;
      }
    }
  }
}
</style>
